import { Injectable, EventEmitter } from '@angular/core';
import VectorLayer from 'ol/layer/Vector';
import { RouteLayerI } from 'src/interfaces/routelayeri';
import { Extent } from 'openlayers';

@Injectable({
  providedIn: 'root'
})
export class RouteLayerService {

  private plannedRoutes: RouteLayerI[] = [];
  private extent: Extent;

  public routeLayerAdderEmitter: EventEmitter<VectorLayer> = new EventEmitter<VectorLayer>();
  public routeLayerRemoveEmitter: EventEmitter<VectorLayer> = new EventEmitter<VectorLayer>();
  public plannedRoutesEmitter: EventEmitter<RouteLayerI[]> = new EventEmitter<RouteLayerI[]>();
  public extentEmitter: EventEmitter<Extent> = new EventEmitter<Extent>();

  constructor() { }

  public addRouteLayer(layer: VectorLayer): void {
    this.routeLayerAdderEmitter.emit(layer);
  }

  public removeLayer(layer: VectorLayer): void {
    this.routeLayerRemoveEmitter.emit(layer);
  }

  public sendPlannedRoutes(routes: RouteLayerI[]) {
    this.plannedRoutes = routes;
    this.plannedRoutesEmitter.emit(routes);
  }

  public getPlannedRoutes(): RouteLayerI[] {
    return this.plannedRoutes;
  }

  public zoomToLayer(extent: Extent) {
    this.extent = extent;
    this.extentEmitter.emit(this.extent);
  }

  public getExtent(): Extent {
    return this.extent;
  }

}
