import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import { RouteLayerService } from '../route-layer.service';
import { ConfigServiceService } from '../config-service.service';
import { ConfigI } from 'src/interfaces/configI';

@Component({
  selector: 'app-route-layer',
  template: '<ng-content></ng-content>'
})
export class RouteLayerComponent implements OnInit, OnDestroy, OnChanges {

  private layer: VectorLayer;
  private configData: ConfigI;

  @Input() opacity: number;
  @Input() visible: boolean;
  @Input() source: VectorSource;

  constructor(
    private routeLayerService: RouteLayerService,
    private configService: ConfigServiceService
  ) { }

  ngOnInit() {

    this.configService.getConfig().subscribe((data) => {

      this.configData = data;

      if (!this.layer) {

        this.layer = new VectorLayer({
          opacity: this.opacity,
          visible: this.visible,
          source: this.source,
          style: this.getPlannedRouteColor
        });

        this.routeLayerService.addRouteLayer(this.layer);

      }

      if (this.visible) {
        this.routeLayerService.zoomToLayer(this.layer.getSource().getExtent());
      }

    });


  }

  ngOnDestroy() {

    if (this.layer) {

      this.routeLayerService.removeLayer(this.layer);

    }

  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.layer) {
      if (changes.visible) {

        this.layer.setVisible(this.visible);

        if (this.visible) {
          this.routeLayerService.zoomToLayer(this.layer.getSource().getExtent());
        }

      } else if (changes.opacity) {

        this.layer.setOpacity(this.opacity);

      }
    }


  }

  private getPlannedRouteColor = (feature: any, resolution: number): Style | Style[] => {

    const olFeature: Feature = <Feature> feature;
    const properties: {[k: string]: any} = olFeature.getProperties();
    const mode: string = properties['mode'];
    const color = this.configData.plannedRouteColors[mode];

    const style: Style = new Style({
        fill: new Fill({
            color: '#00FF00',
        }),
        stroke: new Stroke({
            color: color,
            width: 5
        })
    });

    return style;

}

}
