import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LanguageServiceService } from '../language-service.service';
import { PlannerService } from '../planner.service';
import { LanguageI } from 'src/interfaces/languageI';
import { ConfigServiceService } from '../config-service.service';
import { ConfigI } from 'src/interfaces/configI';
import { MatDatepickerInputEvent, MatRadioChange } from '@angular/material';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-side-plan',
  templateUrl: './side-plan.component.html',
  styleUrls: ['./side-plan.component.css']
})
export class SidePlanComponent implements OnInit {

  public languageData: LanguageI = <LanguageI>{};
  private configData: ConfigI = <ConfigI>{};
  private showRouteOptions = false;
  public planButtonDisable = true;
  public arriveBy = false;
  public minTransferTime = 5;
  public interBttnIcon = 'add';
  public showInter = false;
  public selectedBikeRouteType = 'safety';
  public selectedOptionType = 'SAFE';
  public date = new FormControl(new Date());
  public time: string;
  public isBike = false;
  private routeOptionIsBike = false;
  private routeInterOptionIsBike = false;
  public isTrain = false;
  private routeOptionIsTrain = false;
  private routeInterOptionIsTrain = false;


  constructor(
    private languageService: LanguageServiceService,
    private configService: ConfigServiceService,
    private plannerService: PlannerService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.getLanguageData();
    this.getConfigData();
    this.isPlanEnabled();

    this.getValues();

    this.plannerService.selectedRouteTypeEmitter.subscribe(
      data => {
        let isBike = false;
        let isTrain = false;
        if (data) {

          data.forEach(element => {
            if ((element as string).indexOf("BICYCLE") > -1) {
              isBike = true;
            }

            if ((element as string).indexOf("RAIL") > -1) {
              isTrain = true;
            }
          });
        } 

        this.routeOptionIsBike = isBike;
        this.routeOptionIsTrain = isTrain;

        this.checkRouteType();

      }
    );

    this.plannerService.selectedInterRouteTypeEmitter.subscribe(
      data => {
        let isBike = false;
        let isTrain = false;
        if (data) {

          data.forEach(element => {
            if ((element as string).indexOf("BICYCLE") > -1) {
              isBike = true;
            }

            if ((element as string).indexOf("RAIL") > -1) {
              isTrain = true;
            }
          });
        } 

        this.routeInterOptionIsBike = isBike;
        this.routeInterOptionIsTrain = isTrain;

        this.checkRouteType();

      }
    );

  }

  private getValues(): void {

    this.arriveBy = this.plannerService.getArriveBy();

    const now = new Date();

    if (this.plannerService.getDate() instanceof Date) {

      if (!isNaN(this.plannerService.getDate().getTime())) {

        this.date = new FormControl(this.plannerService.getDate());

      } else {

        this.date = new FormControl(now);

      }


    } else {

      this.date = new FormControl(now);

    }

    if (this.plannerService.getTime()) {

      this.time = this.plannerService.getTime();

    } else {

      this.time = this.datePipe.transform(now, 'HH:mm');

    }

    this.minTransferTime = this.plannerService.getMinTransferTime();
    this.selectedOptionType = this.plannerService.getBikeOptimize();

    switch (1) {
      case this.plannerService.getBikeTriangleSafety():

        this.selectedBikeRouteType = 'safety';

        break;

      case this.plannerService.getBikeTriangleSlope():

        this.selectedBikeRouteType = 'slope';

        break;

      case this.plannerService.getBikeTriangleTime():

        this.selectedBikeRouteType = 'speed';

        break;

      default:
        break;
    }

  }

  private getLanguageData(): void {

    this.languageService.getLanguageData().subscribe(data => {

      this.languageData = data;

    });

    this.languageService.languageEmitter.subscribe(
      data => this.languageData = data
    );

  }

  private getConfigData(): void {

    this.configService.getConfig().subscribe((data) => {

      this.configData = data;

    });

  }

  private isPlanEnabled(): void {

    this.plannerService.enablePlanEmitter.subscribe(
      (data: boolean) => {

        this.planButtonDisable = data;

      }
    );

  }

  public planRoute(): void {

    if (!this.planButtonDisable) {
      this.plannerService.planRoute();
    }
  }

  private showHideRouteOpts() {

    this.showRouteOptions = !this.showRouteOptions;

  }

  private showHideInter() {

    this.showInter = !this.showInter;

    if (this.showInter) {

      this.interBttnIcon = 'remove';

    } else {

      this.interBttnIcon = 'add';

    }


  }

  public swapStartEnd() {

    if (this.plannerService.getStartPoint() !== undefined && this.plannerService.getEndPoint() !== undefined) {

      const preStartPoint = this.plannerService.getStartPoint();
      const preEndPoint = this.plannerService.getEndPoint();

      this.plannerService.setStartPoint(preEndPoint);
      this.plannerService.setEndPoint(preStartPoint);

      this.plannerService.swapStartEnd();

    }

  }

  public setArriveBy(event) {

    this.plannerService.setArriveBy(this.arriveBy);

  }

  public setDate(event: MatDatepickerInputEvent<Date>) {

    this.plannerService.setDate(event.value);

  }

  public setTime(event) {

    this.time = event;
    this.plannerService.setTime(this.time);

  }

  public setMinTransferTime() {

    this.plannerService.setMinTransferTime(this.minTransferTime);

  }

  public setBikeRouteType(event: MatRadioChange) {

    switch (event.value) {
      case 'safety':

        this.plannerService.setBikeTriangleSafety(1);
        this.plannerService.setBikeTriangleSlope(0);
        this.plannerService.setBikeTriangleTime(0);

        break;

      case 'slope':

        this.plannerService.setBikeTriangleSafety(0);
        this.plannerService.setBikeTriangleSlope(1);
        this.plannerService.setBikeTriangleTime(0);

        break;

      case 'speed':

        this.plannerService.setBikeTriangleSafety(0);
        this.plannerService.setBikeTriangleSlope(0);
        this.plannerService.setBikeTriangleTime(1);

        break;

      default:
        break;
    }

  }

  public setBikeRouteOption(event: MatRadioChange) {

    this.selectedOptionType = event.value;
    this.plannerService.setBikeOptimize(this.selectedOptionType);

  }

  public checkRouteType() {

    this.isBike = false;
    this.isTrain = false;

    if (this.routeOptionIsBike) {

      this.isBike = true;

    } 
    
    if (this.routeInterOptionIsBike) {

      this.isBike = true;

    }

    if (this.routeOptionIsTrain) {

      this.isTrain = true;

    }

    if (this.routeInterOptionIsTrain) {

      this.isTrain = true;

    }

  }

}
