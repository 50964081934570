export class RouteTypes {

    public readonly types = {

        modeBicycleTransit: 'TRANSIT,BICYCLE',
        // modeTransit: 'TRANSIT,WALK',
        modeRail: 'TRAM,RAIL,SUBWAY,FUNICULAR,GONDOLA,WALK',
        // modeBikeRide: 'BICYCLE_PARK,WALK,TRANSIT',
        modeBicycle: 'BICYCLE',
        modeWalk: 'WALK',
        modeCar: 'CAR'
        // modeParkRide: 'CAR_PARK,WALK,TRANSIT'

    };

}
