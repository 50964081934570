import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { LanguageServiceService } from '../language-service.service';
import { ProgressBarService } from '../progress-bar.service';
import { LanguageI } from 'src/interfaces/languageI';

@Component({
  selector: 'app-main-menubar',
  templateUrl: './main-menubar.component.html',
  styleUrls: ['./main-menubar.component.css']
})
export class MainMenubarComponent implements OnInit {

  protected title: string;
  protected showProgressBar = false;

  constructor(
    private languageService: LanguageServiceService,
    private progressBarService: ProgressBarService
  ) { }

  ngOnInit() {
    this.getLanguageData();
    this.getProgressBarStatus();
  }

  private getLanguageData() {
    this.languageService.getLanguageData().subscribe(languageData => this.title = languageData.appTitle);

    this.languageService.languageEmitter.subscribe(
      (data: LanguageI) => {
        this.title = data.appTitle;
      }
    );
  }

  private getProgressBarStatus(): void {

    this.progressBarService.progBarStausEmitter.subscribe(
      (data) => this.showProgressBar = data
    );

  }

}
