import { Component, OnInit, Inject } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { LanguageI } from 'src/interfaces/languageI';
import { MatDialogRef, MAT_DIALOG_DATA, MatRadioChange, MatSelectChange } from '@angular/material';
import { DataService } from '../data.service';
import { FuelI } from 'src/interfaces/fueli';

@Component({
  selector: 'app-eco-modal',
  templateUrl: './eco-modal.component.html',
  styleUrls: ['./eco-modal.component.css']
})
export class EcoModalComponent implements OnInit {

  public language: LanguageI = <LanguageI>{};
  public fuelData: FuelI = <FuelI>{};
  public fuelType = 'petrol';
  public selectedCapacity: number;
  public tramPrice: number;
  public estimatedCO: number;
  public fuelPrice: number;

  constructor(
    private languageService: LanguageServiceService,
    private dataService: DataService,
    public dialogRef: MatDialogRef<EcoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) { }

  ngOnInit() {

    this.languageService.getLanguageData().subscribe(data => this.language = data);
    this.languageService.languageEmitter.subscribe(data => this.language = data);

    this.dataService.getFuelData().subscribe(
      (data) => {
        this.fuelData = data;

        this.tramPrice = 0;
        this.estimatedCO = 0;

        this.tramPrice = Math.round((this.data / 1000) * this.fuelData.kmPrice);
        this.estimatedCO = Math.round((this.data / 1000) * this.fuelData.co2Emission);
      });

  }

  public close() {

    this.dialogRef.close();

  }

  public fuelTypeChanged(change: MatRadioChange) {

    this.calculateFuelPrice();

  }

  public cyclinderCapChanged(change: MatSelectChange) {

    this.calculateFuelPrice();

  }

  private calculateFuelPrice() {

    this.fuelPrice = Math.round(this.fuelData[this.fuelType].price * this.selectedCapacity);

  }



}
