import { Injectable, EventEmitter } from '@angular/core';
import { ConfigI } from '../interfaces/configI';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageI } from '../interfaces/languageI';

@Injectable({
  providedIn: 'root'
})
export class LanguageServiceService {

  private readonly langUrl: string = 'language/';
  public languageEmitter: EventEmitter<LanguageI> = new EventEmitter<LanguageI>();
  private language: string;

  constructor(private http: HttpClient) { }

  public getLanguageData(): Observable<LanguageI> {
    return this.http.get<LanguageI>(this.langUrl + this.getLanguageFile() + '.json');
  }

  public getLanguage(): string {
    return this.getLanguageFile();
  }

  public setLanguage(lang: string) {

    this.language = lang;

    this.http.get<LanguageI>(this.langUrl + this.getLanguageFile() + '.json').subscribe(
      (data) => {
        this.languageEmitter.emit(data);
      }
    );

  }

  /**
   * Function to get language file
   */
  private getLanguageFile(): string {

    // let langParam: string|null = Init.getLanguageParam();

    if (this.language) {

      return this.language;

    } else {

      const hunOptions: string[] = ['hu', 'hu-hu'];
      const germanOptions: string[] = ['de', 'de-at', 'de-de', 'de-li', 'de-lu', 'de-ch'];

      const language: string = navigator.language;

      let languageType: string;

      if (hunOptions.indexOf(language) > -1) {

          languageType = 'hu';

      } else if (germanOptions.indexOf(language) > -1) {

          languageType = 'de';

      } else {

          languageType = 'en';

      }

      return languageType;

    }

  }
}
