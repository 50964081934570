/// <reference types="@types/googlemaps" />
import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { LatLonI } from '../../interfaces/latloni';
import { PlannerService } from '../planner.service';
import { LanguageI } from 'src/interfaces/languageI';
import Polygon from 'ol/geom/Polygon';
import Feature from 'ol/Feature';
import { fromLonLat, transform } from 'ol/proj.js';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-places-input',
  templateUrl: './places-input.component.html',
  styleUrls: ['./places-input.component.css']
})
export class PlacesInputComponent implements OnInit, AfterViewInit {

  @Input() pointType;
  public placeholder: string;
  public label: string;
  private searchBox: google.maps.places.SearchBox;
  protected inputValue: string;
  @ViewChild('search') searchBoxElement: ElementRef;
  private projectArea: Feature;
  public languageData: LanguageI = <LanguageI>{};

  constructor(
    private languageService: LanguageServiceService,
    private plannerService: PlannerService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getLanguageData();
    this.getPointText();

    this.plannerService.projectAreaEmitter.subscribe(
      (data) => {
        this.projectArea = data;
      }
    );

  }

  ngAfterViewInit() {
    this.initInputBox();
    const inputElem: HTMLInputElement = this.searchBoxElement.nativeElement;
    inputElem.onkeyup = () => {
      this.removePlaces(inputElem.value);
    };
  }

  private getLanguageData() {
    this.languageService.getLanguageData().subscribe(
      (languageData) => {
      this.setContentLanguage(languageData);
      this.languageData = languageData;
    });

    this.languageService.languageEmitter.subscribe(
      (data) => {
        this.setContentLanguage(data);
        this.languageData = data;
      }
    );
  }

  private setContentLanguage(data: LanguageI): void {
    this.placeholder = data[this.pointType + 'PointInputPlaceholder'],
    this.label = data[this.pointType + 'PointInputLabel'];
  }

  private getPointText() {

    if (this.pointType === 'start') {

      this.inputValue = this.plannerService.getStartPointText();
      this.plannerService.startTextEmitter.subscribe(data => this.inputValue = data);

    } else if (this.pointType === 'end') {

      this.inputValue = this.plannerService.getEndPointText();
      this.plannerService.endTextEmitter.subscribe(data => this.inputValue = data);

    } else if (this.pointType === 'inter') {

      this.inputValue = this.plannerService.getInterPointText();
      this.plannerService.interTextEmitter.subscribe(data => this.inputValue = data);

    }

  }

  private initInputBox(): void {

    const input: HTMLInputElement = <HTMLInputElement> document.getElementById('input_' + this.pointType);
    const bounds = new google.maps.LatLngBounds(
      {
        lat: 46.3604628,
        lng: 15.8289712
      },
      {
        lat: 48.4031463,
        lng: 18.1601262
      }
    );
    this.searchBox = new google.maps.places.SearchBox(input, {
      bounds: bounds
    });

    this.searchBox.addListener('places_changed', () => {

      const place: google.maps.places.PlaceResult = this.searchBox.getPlaces()[0];
      const latLon: LatLonI = <LatLonI> {};

      latLon.lat = place.geometry.location.lat();
      latLon.lon = place.geometry.location.lng();

      if (this.pointType === 'start') {

        this.plannerService.setStartPoint(latLon);
        this.plannerService.setStartPointText(input.value);

        if (!this.checkPointIsInsideProjArea(fromLonLat([latLon.lon, latLon.lat]))) {

          this.snackbar.open(this.languageData.outOfArea)._dismissAfter(3000);
          this.plannerService.setStartPointText('');
          input.value = '';
          this.removePlaces('');
          return;

        }

      } else if (this.pointType === 'end') {

        this.plannerService.setEndPoint(latLon);
        this.plannerService.setEndPointText(input.value);

        if (!this.checkPointIsInsideProjArea(fromLonLat([latLon.lon, latLon.lat]))) {

          this.snackbar.open(this.languageData.outOfArea)._dismissAfter(3000);
          this.plannerService.setStartPointText('');
          input.value = '';
          this.removePlaces('');
          return;

        }

      } else if (this.pointType === 'inter') {

        this.plannerService.setIntermediatePoint(latLon);
        this.plannerService.setInterPointText(input.value);

        if (!this.checkPointIsInsideProjArea(fromLonLat([latLon.lon, latLon.lat]))) {

          this.snackbar.open(this.languageData.outOfArea)._dismissAfter(3000);
          this.plannerService.setStartPointText('');
          input.value = '';
          this.removePlaces('');
          return;

        }

      }

    });

  }

  public usePosition(): void {

    if (this.pointType === 'start') {



    } else if (this.pointType === 'end') {



    } else if (this.pointType === 'inter') {



    }

  }

  public removePlaces(value: string): void {

    if (value === '') {

      if (this.pointType === 'start') {

        this.plannerService.removeStartPoint();

      } else if (this.pointType === 'end') {

        this.plannerService.removeEndPoint();

      } else if (this.pointType === 'inter') {

        this.plannerService.removeIntermediatePoint();

      }

    }

  }

  private checkPointIsInsideProjArea(point: [number, number]): boolean {

    const poly = <Polygon> this.projectArea.getGeometry();

    return poly.intersectsCoordinate(point);

}

}
