import { Injectable, EventEmitter } from '@angular/core';
import { LatLonI } from '../interfaces/latloni';
import { PlannerRequestI } from '../interfaces/otp_intefaces/plannerReqI';
import { DatePipe } from '@angular/common';
import { ConfigServiceService } from './config-service.service';
import { ConfigI } from '../interfaces/configI';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PlannerRespI } from '../interfaces/otp_intefaces/plannerRespI';
import { LanguageServiceService } from './language-service.service';
import { LanguageI } from 'src/interfaces/languageI';
import { MatSnackBar } from '@angular/material';
import { ProgressBarService } from './progress-bar.service';
import Feature from 'ol/Feature';

@Injectable({
  providedIn: 'root'
})
export class PlannerService {

  private startPoint: LatLonI;
  private endPoint: LatLonI;
  private intermediatePoint: LatLonI;
  private selectedRouteTypes: any[];
  private interSelectedRouteTypes: any[];
  private planDisabled = false;
  private plannedRoutes: Array<PlannerRespI> = [];
  private routesNum: number;
  private plannedRouteChange;

  private startPointText: string;
  private endPointText: string;
  private interPointText: string;

  public readonly ecoFootPrintEmName = 'ecoFootprint';
  public readonly simpleFirstEmName = 'simpleFirst';

  public startPointEmitter: EventEmitter<any> = new EventEmitter();
  public endPointEmitter: EventEmitter<any> = new EventEmitter();
  public intermediatePointEmitter: EventEmitter<any> = new EventEmitter();
  public enablePlanEmitter: EventEmitter<any> = new EventEmitter();
  public ecoFootprint: EventEmitter<any> = new EventEmitter<any>();
  public routeEmitter: EventEmitter<PlannerRespI[]> = new EventEmitter<PlannerRespI[]>();
  public startTextEmitter: EventEmitter<string> = new EventEmitter<string>();
  public endTextEmitter: EventEmitter<string> = new EventEmitter<string>();
  public interTextEmitter: EventEmitter<string> = new EventEmitter<string>();
  public projectAreaEmitter: EventEmitter<Feature> = new EventEmitter<Feature>();
  public selectedRouteTypeEmitter: EventEmitter<string[]> = new EventEmitter<string[]>();
  public selectedInterRouteTypeEmitter: EventEmitter<string[]> = new EventEmitter<string[]>();

  private configData: ConfigI;
  private languageData: LanguageI;

  private reqOptions: PlannerRequestI = <PlannerRequestI> {
    wheelchair: false,
    maxWalkDistance: 100000,
    optimize: 'SAFE',
    arriveBy: false,
    triangleSafetyFactor: 1,
    triangleSlopeFactor: 0,
    triangleTimeFactor: 0,
    numItineraries: 3,
    minTransferTime: 300
  };

  constructor(
    private datePipe: DatePipe,
    private configService: ConfigServiceService,
    private languageService: LanguageServiceService,
    private progressBarService: ProgressBarService,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {
    /*this.differ = this.differs.find([]).create(null);
    console.log(this.differ);*/
    this.getConfigData();
    this.getLanguageData();

    const origArray: PlannerRespI[] = [];

    this.plannedRouteChange = {
      get: (target, property) => {
        return target[property];
      },
      set: (target, property, value, receiver) => {
        target[property] = value;
        this.routePlanChanged();
        return true;
      }
    };

    this.plannedRoutes = new Proxy(origArray, this.plannedRouteChange);

  }

  private routePlanChanged(): void {

    if (this.plannedRoutes.length === this.routesNum) {

      this.routeEmitter.emit(this.plannedRoutes);
      this.progressBarService.showProgressBar(false);

    }

  }

  public getStartPointText(): string {

    return this.startPointText;

  }

  public setStartPointText(text: string): void {

    this.startPointText = text;

  }

  public getEndPointText(): string {

    return this.endPointText;

  }

  public setEndPointText(text: string): void {

    this.endPointText = text;

  }

  public getInterPointText(): string {

    return this.interPointText;

  }

  public setInterPointText(text: string): void {

    this.interPointText = text;

  }

  private getConfigData(): void {

    this.configService.getConfig().subscribe(data => this.configData = data);

  }

  private getLanguageData() {

    this.languageService.getLanguageData().subscribe(data => this.languageData = data);

  }

  public setStartPoint(point: LatLonI): void {

    this.startPoint = point;
    this.checkIsPlanPossible();
    this.startPointEmitter.emit(this.startPoint);

  }

  public getStartPoint(): LatLonI {

    return this.startPoint;

  }

  public setEndPoint(point: LatLonI): void {

    this.endPoint = point;
    this.checkIsPlanPossible();
    this.endPointEmitter.emit(this.endPoint);

  }

  public getEndPoint(): LatLonI {

    return this.endPoint;

  }

  public setIntermediatePoint(point: LatLonI): void {

    this.intermediatePoint = point;
    this.intermediatePointEmitter.emit(this.intermediatePoint);

  }

  public getInterPoint(): LatLonI {

    return this.intermediatePoint;

  }

  public removeStartPoint(): void {

    this.startPoint = undefined;
    this.checkIsPlanPossible();
    this.startPointEmitter.emit(this.startPoint);

  }

  public removeEndPoint(): void {

    this.endPoint = undefined;
    this.checkIsPlanPossible();
    this.endPointEmitter.emit(this.endPoint);

  }

  public removeIntermediatePoint(): void {

    this.intermediatePoint = undefined;
    this.intermediatePointEmitter.emit(this.intermediatePoint);

  }

  public swapStartEnd(): void {

    const prevStart = this.startPointText;
    const prevEnd = this.endPointText;

    this.startTextEmitter.emit(prevEnd);
    this.endTextEmitter.emit(prevStart);
  }

  public setSelectedRouteTypes(values: any[]): void {

    if (values.length > 3) {

      this.snackBar.open(this.languageData.routeTypeOptionsMaxExceed)._dismissAfter(5000);

    }

    this.selectedRouteTypes = values;
    this.selectedRouteTypeEmitter.emit(this.selectedRouteTypes);
    this.checkIsPlanPossible();

  }

  public setSelectedInterRouteTypes (values: any[]): void {

    if (values.length > 3) {

      this.snackBar.open(this.languageData.routeTypeOptionsMaxExceed)._dismissAfter(5000);

    }

    this.interSelectedRouteTypes = values;
    this.selectedInterRouteTypeEmitter.emit(this.interSelectedRouteTypes);
    this.checkIsPlanPossible();

  }

  public getSelectedRouteTypes(): any[] {

    return this.selectedRouteTypes;

  }

  public getSelectedInterRouteTypes(): any[] {

    return this.interSelectedRouteTypes;

  }

  public setArriveBy(arriveBy: boolean) {

    this.reqOptions.arriveBy = arriveBy;

  }

  public getArriveBy(): boolean {

    return this.reqOptions.arriveBy;

  }

  public setDate(date: Date) {

    this.reqOptions.date = this.datePipe.transform(date, 'MM-dd-yyyy');

  }

  public getDate(): Date {

    return new Date(this.reqOptions.date);

  }

  public setFromPlace(fromPlace: string) {

    this.reqOptions.fromPlace = fromPlace;

  }

  public setInterPlace(interPlace: string) {

    this.reqOptions.intermediatePlaces = interPlace;

  }

  public setToPlace(toPlace: string) {

    this.reqOptions.toPlace = toPlace;

  }

  public setMode(mode: string) {

    this.reqOptions.mode = mode;

  }

  public setTime(time: string) {

    this.reqOptions.time = time;

  }

  public getTime(): string {

    return this.reqOptions.time;

  }

  public setBikeTriangleSafety(safety: number) {

      this.reqOptions.triangleSafetyFactor = safety;

  }

  public getBikeTriangleSafety(): number {

      return this.reqOptions.triangleSafetyFactor;

  }

  public setBikeTriangleSlope(slope: number) {

      this.reqOptions.triangleSlopeFactor = slope;

  }

  public getBikeTriangleSlope(): number {

      return this.reqOptions.triangleSlopeFactor;

  }

  public setBikeTriangleTime(time: number) {

      this.reqOptions.triangleTimeFactor = time;

  }

  public getBikeTriangleTime(): number {

      return this.reqOptions.triangleTimeFactor;

  }

  public setBikeOptimize(type: string) {

    this.reqOptions.optimize = type;

  }

  public getBikeOptimize(): string {

    return this.reqOptions.optimize;

  }

  public setMinTransferTime(transferTimeMin: number): void {

    this.reqOptions.minTransferTime = transferTimeMin * 60;

  }

  public getMinTransferTime(): number {

    return this.reqOptions.minTransferTime / 60;

  }

  private checkIsPlanPossible(): void {

    if (this.startPoint
        && this.endPoint
        && this.selectedRouteTypes
        && (this.selectedRouteTypes.length > 0 && this.selectedRouteTypes.length < 4)) {

      this.planDisabled = false;

    } else {

      this.planDisabled = true;

    }

    this.enablePlanEmitter.emit(this.planDisabled);

  }

  public planRoute(): void {

    this.plannedRoutes.splice(0, this.plannedRoutes.length);

    const date = new Date();

    if (this.startPoint && this.endPoint && this.selectedRouteTypes && this.selectedRouteTypes.length !== 0) {

      const url: string = this.configData.otpUrl + 'routers/' + this.configData.routerName + '/plan';

      if (!this.reqOptions.date) {
        const dateStr: string = this.datePipe.transform(date, 'MM-dd-yyyy');
        this.reqOptions.date = dateStr;
      }

      if (!this.reqOptions.time) {
        const timeStr: string = this.datePipe.transform(date, 'HH:mm');
        this.reqOptions.time = timeStr;
      }

      this.reqOptions.fromPlace = this.startPoint.lat.toString() + ',' + this.startPoint.lon.toString();
      this.reqOptions.toPlace = this.endPoint.lat.toString() + ',' + this.endPoint.lon.toString();

      if (this.selectedRouteTypes.length === 1 && this.intermediatePoint === undefined) {

        this.routesNum = 1;

        this.reqOptions.mode = this.selectedRouteTypes[0];

        const httpParams: HttpParams = this.reqOptToHttpParams(this.reqOptions);

        this.progressBarService.showProgressBar(true);

        this.http.get<PlannerRespI>(url, {
            params: httpParams
          }).subscribe(
            (data) => {
              this.plannedRoutes.push(data);
            },
            (error) => {
              this.snackBar.open(this.languageData.routePlanErrorMsg)._dismissAfter(5000);
              this.progressBarService.showProgressBar(false);
            }
          );

      } else if (this.intermediatePoint !== undefined) {

        this.routesNum = 2;

        this.reqOptions.mode = this.interSelectedRouteTypes[0];

        this.reqOptions.numItineraries = 1;

        this.reqOptions.fromPlace = this.startPoint.lat.toString() + ',' + this.startPoint.lon.toString();
        this.reqOptions.toPlace = this.intermediatePoint.lat.toString() + ',' + this.intermediatePoint.lon.toString();

        let httpParams: HttpParams = this.reqOptToHttpParams(this.reqOptions);

        this.progressBarService.showProgressBar(true);

        this.http.get<PlannerRespI>(url, {
          params: httpParams
        }).subscribe(
          (data) => {

            this.plannedRoutes.push(data);

            const startDate: Date = new Date(data.plan.itineraries[0].endTime);

            const startDateStr: string = this.datePipe.transform(startDate, 'MM-dd-yyyy');
            const startTimeStr: string = this.datePipe.transform(startDate, 'HH:mm');

            this.reqOptions.fromPlace = this.intermediatePoint.lat.toString() + ',' + this.intermediatePoint.lon.toString();
            this.reqOptions.toPlace = this.endPoint.lat.toString() + ',' + this.endPoint.lon.toString();

            this.reqOptions.mode = this.selectedRouteTypes[0];

            this.reqOptions.date = startDateStr;
            this.reqOptions.time = startTimeStr;

            httpParams = this.reqOptToHttpParams(this.reqOptions);

            this.http.get<PlannerRespI>(url, {
              params: httpParams
            }).subscribe(
              (secondData) => {
                this.plannedRoutes.push(secondData);
              },
              (error) => {
                this.snackBar.open(this.languageData.routePlanErrorMsg)._dismissAfter(5000);
                this.progressBarService.showProgressBar(false);
              }
            );

          },
          (error) => {
            this.snackBar.open(this.languageData.routePlanErrorMsg)._dismissAfter(5000);
            this.progressBarService.showProgressBar(false);
          }
        );

      } else {

        this.routesNum = this.selectedRouteTypes.length;

        this.selectedRouteTypes.forEach((type) => {

          this.reqOptions.mode = type;

          this.reqOptions.numItineraries = 1;

          const httpParams: HttpParams = this.reqOptToHttpParams(this.reqOptions);

          this.progressBarService.showProgressBar(true);

          this.http.get<PlannerRespI>(url, {
            params: httpParams
          }).subscribe(
            (data) => {
              this.plannedRoutes.push(data);
            },
            (error) => {
              this.snackBar.open(this.languageData.routePlanErrorMsg)._dismissAfter(5000);
              this.progressBarService.showProgressBar(false);
            }
          );

        });

      }

    }

  }

  private reqOptToHttpParams(reqOpts: PlannerRequestI): HttpParams {

    let httpParams: HttpParams = new HttpParams();

    for (const key in reqOpts) {

      if (reqOpts[key] !== undefined) {

        httpParams = httpParams.set(key, reqOpts[key]);

      }

    }

    return httpParams;

  }

  public setProjectArea(feature: Feature) {

    this.projectAreaEmitter.emit(feature);

  }

}
