import { Component, OnInit, Input, IterableDiffers, DoCheck, EventEmitter, Output } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { PlannerService } from '../planner.service';
import { RouteTypes } from '../../app/routeTypes';
import { LanguageI } from 'src/interfaces/languageI';
import { MatSelectionListChange, MatOptionSelectionChange, MatSelectChange } from '@angular/material';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css']
})
export class MultiSelectComponent implements OnInit, DoCheck {

  constructor(
    private languageService: LanguageServiceService,
    private iterableDiffers: IterableDiffers,
    private plannerService: PlannerService
  ) {
    this.iterableDiffer = this.iterableDiffers.find([]).create(null);
  }

  @Input() listType;
  @Input() subType;
  protected listOptions: {key: string, value: any}[] = [];
  protected placeholder: string;
  protected values: any[];
  private iterableDiffer;
  private routeTypes: RouteTypes = new RouteTypes();
  // @Output() selectedValue: EventEmitter<any> = new EventEmitter<any>(); 

  ngOnInit() {
    this.getLanguageData();
    this.setValues();
  }

  ngDoCheck() {

    const changes = this.iterableDiffer.diff(this.values);

    if (changes) {

      this.sendSelectedValues();

    }
  }

  // public selectionChanged(selected: MatSelectChange) {

  //   console.log(selected);

  //   // this.selectedValue.emit(selected.option.value);

  // }

  private setValues() {

    if (this.subType === 'normal') {

      if (this.plannerService.getSelectedRouteTypes()) {
        this.values = this.plannerService.getSelectedRouteTypes();
      }

    } else if (this.subType === 'inter') {

      if (this.plannerService.getSelectedInterRouteTypes()) {
        this.values = this.plannerService.getSelectedInterRouteTypes();
      }

    }

  }

  private getLanguageData(): void {

    this.languageService.getLanguageData().subscribe(data => {
      this.setContentLang(data);
    });

  this.languageService.languageEmitter.subscribe(
    (data) => {
      this.setContentLang(data);
    }
  );

  }

  private setContentLang(data: LanguageI): void {

    // this.listOptions = [<{key: string, value: any}>{}];

    this.listOptions = [];

    for (const key in data[this.listType]) {

      if (data[this.listType][key]) {

        const option: {key: string, value: any} = {key: this.routeTypes.types[key], value: data[this.listType][key]};

        this.listOptions.push(option);

      }

    }

    this.placeholder = data.defaultOptionLabel;

  }

  private sendSelectedValues(): void {

    if (this.subType === 'normal') {

      this.plannerService.setSelectedRouteTypes(this.values);

    } else if (this.subType === 'inter') {

      this.plannerService.setSelectedInterRouteTypes(this.values);

    }

  }

}
