import { Component, OnInit } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { LanguageI } from 'src/interfaces/languageI';
import { RouteLayerService } from '../route-layer.service';
import { RouteLayerI } from 'src/interfaces/routelayeri';
import { SidebarService } from '../sidebar.service';
import { Extent } from 'openlayers';
import { MatDialog } from '@angular/material';
import { EcoModalComponent } from '../eco-modal/eco-modal.component';
import LineString from 'ol/geom/LineString';

@Component({
  selector: 'app-side-result',
  templateUrl: './side-result.component.html',
  styleUrls: ['./side-result.component.css']
})
export class SideResultComponent implements OnInit {

  public languageData: LanguageI = <LanguageI>{};
  public routeLayers: RouteLayerI[] = [];
  public title: string;
  public expended = 0;
  public distance: number;

  constructor(
    private languageService: LanguageServiceService,
    private routeLayerService: RouteLayerService,
    private sideBarService: SidebarService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {

    this.getLanguageData();

  }

  private getLanguageData(): void {

    this.languageService.getLanguageData().subscribe(
      (data) => {
        this.setContentLanguage(data);
        this.observeRouteLayers();
      }
    );

    this.languageService.languageEmitter.subscribe(
      (data) => {
        this.setContentLanguage(data);
      }
    );

  }

  private setContentLanguage(data: LanguageI): void {
    this.languageData = data;
    this.title = this.languageData.itinerOptionsCardTitle;
  }

  private observeRouteLayers(): void {

    this.routeLayers = this.routeLayerService.getPlannedRoutes();

    this.routeLayerService.plannedRoutesEmitter.subscribe(
      (data) => {
        this.routeLayers = data;
      }
    );



  }

  protected setExpanded(openedItem: number) {

    this.expended = openedItem;

    this.routeLayers.forEach((layer, i) => {

      if (openedItem === i) {

        layer.visible = true;

        this.distance = layer.info.distance;

      } else {

        layer.visible = false;

      }

    });

  }

  protected backToPlan(): void {

    this.sideBarService.showPlan();

  }

  protected zoomToSection(extent: Extent) {

    this.routeLayerService.zoomToLayer(extent);

  }

  public openDialog() {

    this.dialog.open(EcoModalComponent, {
      minHeight: '95%',
      minWidth: '25%',
      maxHeight: '95%',
      maxWidth: '95%',
      height: '95%',
      width: '300px',
      disableClose: true,
      data: this.distance
    });

  }

  

}
