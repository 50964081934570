import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  protected componentToShow = 'side-plan';

  constructor(
    private sidebarService: SidebarService
  ) { }

  ngOnInit() {

    this.getComponentToShow();

  }

  private getComponentToShow() {

    this.sidebarService.shownComponentEmitter.subscribe(
      (data) => this.componentToShow = data
    );

  }

}
