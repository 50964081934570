import { Injectable } from '@angular/core';
import { ConfigI } from '../interfaces/configI';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigServiceService {

  private readonly configUrl: string = 'config/config.json';

  constructor(private http: HttpClient) { }

  getConfig(): Observable<ConfigI> {
    return this.http.get<ConfigI>(this.configUrl);
  }

}
