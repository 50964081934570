import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  public shownComponent: string;
  public shownComponentEmitter: EventEmitter<any> = new EventEmitter<any>();
  public readonly SIDE_RESULT = 'side-result';
  public readonly SIDE_PLAN = 'side-plan';
  public readonly SIDE_ECOFOOTPRINT = 'side-ecofootprint';

  constructor() { }

  private setComponentToShow(toShow: string): void {

    this.shownComponent = toShow;
    this.shownComponentEmitter.emit(this.shownComponent);

  }

  public showPlan(): void {

    this.setComponentToShow(this.SIDE_PLAN);

  }

  public showResult(): void {

    this.setComponentToShow(this.SIDE_RESULT);

  }

  public showEcofootprint(): void {

    this.setComponentToShow(this.SIDE_ECOFOOTPRINT);

  }


}
