import { Component, OnInit, AfterViewInit } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM';
import {defaults as defaultControls} from 'ol/control.js';
import { fromLonLat, transform } from 'ol/proj.js';
import Geolocation from 'ol/Geolocation';
import Geometry from 'ol/geom/Geometry';
import Feature from 'ol/Feature';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Circle from 'ol/style/Circle';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
import GeoJSON from 'ol/format/GeoJSON';
import { LanguageServiceService } from '../language-service.service';
import { LanguageI } from '../../interfaces/languageI';
import { LatLonI } from '../../interfaces/latloni';
import { PlannerService } from '../planner.service';
import { PlannerRespI } from 'src/interfaces/otp_intefaces/plannerRespI';
import { ConfigServiceService } from '../config-service.service';
import { ConfigI } from 'src/interfaces/configI';
import { MatSnackBar } from '@angular/material';
import { ItinaryI } from 'src/interfaces/otp_intefaces/itinaryi';
import { RouteLayerI } from 'src/interfaces/routelayeri';
import { SidebarService } from '../sidebar.service';
import { RouteLayerService } from '../route-layer.service';
import { RouteInfoI } from 'src/interfaces/routeinfoi';
import { RouteSectionInfo } from 'src/interfaces/routesectioni';
import { RouteTypes } from '../routeTypes';
import { ProjectreaService } from '../projectrea.service';

@Component({
    selector: 'app-ol-map',
    templateUrl: './ol-map.component.html',
    styleUrls: ['./ol-map.component.css']
})
export class OlMapComponent implements OnInit, AfterViewInit {

    public map: Map;
    private geoloc: Geolocation;
    private accuracyFeature: Feature = new Feature();
    private positionFeature: Feature;
    private positionLayer: VectorLayer;
    private trackZoomLevel = 18;
    private startEndPointLayer: VectorLayer;
    private startEndPointSource: VectorSource;
    private startPointFeature: Feature;
    private interPointFeature: Feature;
    private endPointFeature: Feature;
    protected posIcon = 'gps_fixed';
    private languageData: LanguageI = <LanguageI> {};
    private configData: ConfigI;
    protected zoomInTooltip: string;
    protected zoomOutTooltip: string;
    protected positionTooltip: string;
    private plannedRoutes: PlannerRespI[] = [];
    private routeTypes: RouteTypes = new RouteTypes();
    private projarea: Feature;

    constructor(
        private configService: ConfigServiceService,
        private languageService: LanguageServiceService,
        private plannerService: PlannerService,
        private sidebarService: SidebarService,
        private routeService: RouteLayerService,
        private snackBar: MatSnackBar,
        private projectAreaService: ProjectreaService
    ) { }

    ngOnInit() {

        this.map = new Map({
            target: 'map',
            layers: [
                new TileLayer({
                    source: new OSM()
                })
            ],
            controls : defaultControls({
                attribution: false,
                zoom: false
            }),
            view: new View({
                center: fromLonLat([16.224899, 47.205134]),
                zoom: 8,
                minZoom: 8,
                extent: [1562073, 5638303, 2321576, 6374188]
            })
        });

        this.getConfigData();
        this.getLanguageData();
        this.initStartEndPointLayer();
        this.getStartPoint();
        this.getIntermediatePoint();
        this.getEndPoint();
        this.subscribeRouteEmitters();
        this.observeRouteLayersChange();
        this.subscribeZoomToLayer();

        this.projectAreaService.getProjectArea().subscribe(
            (data) => {

                this.addProjarea(data);

            }
        );

        this.map.updateSize();

    }

    ngAfterViewInit() {
        this.map.updateSize();
    }


    private getStartPoint(): void {

        this.plannerService.startPointEmitter.subscribe(
            (latLon) => {
                if (latLon !== undefined) {
                    this.addStartPoint(latLon);
                } else {
                    this.removeStartPoint();
                }
            }
        );

    }

    private getEndPoint(): void {

        this.plannerService.endPointEmitter.subscribe(
            (latLon) => {
                if (latLon !== undefined) {
                    this.addEndPoint(latLon);
                } else {
                    this.removeEndPoint();
                }
            }
        );

    }

    private getIntermediatePoint(): void {

        this.plannerService.intermediatePointEmitter.subscribe(
            (latLon) => {
                if (latLon !== undefined) {
                    this.addIntermediatePoint(latLon);
                } else {
                    this.removeInterPoint();
                }
            }
        );

    }

    private getConfigData(): void {

        this.configService.getConfig().subscribe(data => this.configData = data);

    }

    private getLanguageData(): void {
        this.languageService.getLanguageData().subscribe(data => {
            this.setContentLanguage(data);
        });

        this.languageService.languageEmitter.subscribe(
            (data) => {
                this.setContentLanguage(data);
            }
        );
    }

    private setContentLanguage(data: LanguageI): void {
        this.languageData = data;
        this.zoomInTooltip = data.zoomInTooltip;
        this.zoomOutTooltip = data.zoomOutTooltip;
        this.positionTooltip = data.locationTooltipOff;
    }

    private subscribeRouteEmitters(): void {

        this.plannerService.routeEmitter.subscribe(

            (data) => {

                const routeLayerSources: RouteLayerI[] = [];

                this.plannedRoutes = data;

                this.plannedRoutes.forEach(route => {

                    if (route.plan) {

                        route.plan.itineraries.forEach(itiner => {

                            const source = this.processRouteResult(itiner);
    
                            const routeLayer: RouteLayerI = <RouteLayerI>{};
                            routeLayer.source = source;
                            routeLayer.opacity = 1;
                            routeLayer.visible = false;
                            routeLayer.info = <RouteInfoI>{};
                            const durationTrip = this.secondsToMins(source.get('duration'));
    
                            let distance = 0;
    
                            itiner.legs.forEach(leg => {
    
                                distance = distance + leg.distance;
    
                            });
    
                            routeLayer.info.durationHH = durationTrip.hour;
                            routeLayer.info.durationmm = durationTrip.min;
                            routeLayer.info.startTime = new Date(source.get('startTime'));
                            routeLayer.info.arriveTime = new Date(source.get('endTime'));
                            routeLayer.info.sections = [];
                            routeLayer.info.distance = distance;
    
                            source.forEachFeature(feature => {
    
                                const info = <RouteSectionInfo>{};
    
                                const durationLeg = this.secondsToMins(feature.get('duration'));
    
                                info.durationHH = durationLeg.hour;
                                info.durationmm = durationLeg.min;
                                info.durations = durationLeg.sec;
                                info.end = new Date(feature.get('endTime'));
                                info.start = new Date(feature.get('startTime'));
                                info.mode = feature.get('mode');
                                info.distance = feature.get('distance');
                                info.extent = feature.getGeometry().getExtent();
    
                                if (feature.get('mode') === 'RAIL') {
    
                                    info.railFrom = feature.get('railFrom');
                                    info.railTo = feature.get('railTo');
    
                                }
    
                                routeLayer.info.sections.push(info);
    
                            });
    
                            routeLayerSources.push(routeLayer);
    
                        });

    
                        routeLayerSources[0].visible = true;
                        this.sidebarService.showResult();
                        this.routeService.sendPlannedRoutes(routeLayerSources);

                    } else {

                        const snackbar = this.snackBar.open(this.languageData.routePlanErrorMsg, 'OK', {
                            duration: 5000
                        })

                        snackbar.afterDismissed().subscribe(
                            _ => window.location.reload()
                        );

                        snackbar.onAction().subscribe(
                            _ => window.location.reload()
                        )

                    }

                })
                    

            });

    }

    private processRouteResult(itiner: ItinaryI): VectorSource {

        const vectorLine: VectorSource = new VectorSource();

        itiner.legs.forEach(leg => {

            const points: [number, number][] = [];

           const encodedLine: google.maps.LatLng[] = google.maps.geometry.encoding.decodePath(leg.legGeometry.points);

            encodedLine.forEach(point => {

                points.push(transform([point.lng(), point.lat()], 'EPSG:4326', 'EPSG:3857'));

            });

            const lineSegment: Feature = new Feature();
            lineSegment.setGeometry(new LineString(points));
            lineSegment.setProperties({
                duration: leg.duration,
                endTime: leg.endTime,
                startTime: leg.startTime,
                alerts: leg.alerts,
                mode: leg.mode,
                realTime: leg.realTime,
                steps: leg.steps
            });

            if (leg.mode === 'RAIL') {
                lineSegment.setProperties({
                    railFrom: leg.from.name,
                    railTo: leg.to.name
                });
            }

            vectorLine.addFeature(lineSegment);
            vectorLine.setProperties({

                duration: itiner.duration,
                startTime: itiner.startTime,
                endTime: itiner.endTime,
                transitTime: itiner.transitTime,
                transfers: itiner.transfers,
                legs: itiner.legs

            });

        });

        return vectorLine;

    }

    private secondsToMins(secs: number): {hour: number, min: number, sec: number} {

        const hour = Math.floor(secs / 3600);
        const min = ((secs / 3600) - hour) * 60;
        const sec = (min - Math.floor(min)) * 60;

        return {hour: hour, min: Math.floor(min), sec: Math.floor(sec)};

    }

    private observeRouteLayersChange(): void {

        this.routeService.routeLayerAdderEmitter.subscribe(
            (data) => {
                this.map.addLayer(data);
            }
        );

        this.routeService.routeLayerRemoveEmitter.subscribe(
            (data) => {
                this.map.removeLayer(data);
            }
        );

    }

    /**
     * Function to increase zoom level by one
     */
    public zoomIn(): void {

        this.setZoom(this.map.getView().getZoom() + 1);

    }

    /**
     * Function to degerase zoom level by one
     */
    public zoomOut(): void {

        this.setZoom(this.map.getView().getZoom() - 1);

    }

    /**
     * Function to set zoom level
     *
     * @param zoom zoom level to set
     */
    private setZoom(zoom: number): void {

        this.map.getView().animate({
            zoom: zoom,
            duration: 250
        });

    }

    private subscribeZoomToLayer(): void {

        this.routeService.extentEmitter.subscribe(
            (data) => {
                console.log(data);
                this.map.getView().fit(data);
            }
        );

    }

    public trackLocation(lat: number, lon: number, zoom: number): void {

        this.map.getView().animate({
            zoom: zoom,
            center: [lat, lon],
            duration: 250
        });

    }

    /**
     * Function to use device's geolocation
     */
    public showCurrentLocation(): void {

        if (!this.geoloc) {

            this.geoloc = new Geolocation({

                projection: this.map.getView().getProjection()

            });
        }

        this.positionFeature = new Feature();
        this.positionFeature.setStyle(new Style({
            image: new Circle({
                radius: 6,
                fill: new Fill({
                    color: '#3399CC'
                }),
                stroke: new Stroke({
                    color: '#fff',
                    width: 2
                })
            })
        }));

        this.positionLayer = new VectorLayer();
        this.positionLayer.setSource(new VectorSource({

            features: [this.accuracyFeature, this.positionFeature]

        }));

        this.map.addLayer(this.positionLayer);

        this.geoloc.on('error', (error) => {

            this.snackBar.open(this.languageData.locErrorMsg)._dismissAfter(5000);

        });

        this.geoloc.on('change:accuracyGeometry', () => {

            this.accuracyFeature.setGeometry(this.geoloc.getAccuracyGeometry());

        });

        this.geoloc.on('change:position', () => {

            const coordinates: [number, number] = this.geoloc.getPosition();

            if (coordinates) {

                this.positionFeature.setGeometry(

                    new Point(coordinates)

               );

                this.trackLocation(coordinates[0], coordinates[1], this.trackZoomLevel);
            }

        });

        this.geoloc.setTrackingOptions({

            enableHighAccuracy: true

        });

        this.geoloc.setTracking(true);
        this.posIcon = 'gps_not_fixed';
        this.positionTooltip = this.languageData.locationTooltipOn;

    }

    /**
     * Function to remove tracking from map
     */
    public hideCurrentLocation(): void {

        this.map.removeLayer(this.positionLayer);
        this.geoloc.setTracking(false);
        this.posIcon = 'gps_fixed';
        this.positionTooltip = this.languageData.locationTooltipOff;

    }

    private posFunction() {

        if (this.posIcon === 'gps_fixed') {

            this.showCurrentLocation();

        } else {

            this.hideCurrentLocation();

        }

    }

    /**
     * Function to add start point to map and to planner class
     *
     * @param results geocoding result
     * @param status geocoding status
     */
    public addStartPoint(latLon: LatLonI): void {

        if (!this.startPointFeature) {

            this.startPointFeature = new Feature();
            this.startPointFeature.setStyle(new Style({
                image:  new Circle({
                    radius:  8,
                    fill:  new Fill({
                        color:  '#0c9904'
                    }),
                    stroke:  new Stroke({
                        color:  '#fff',
                        width:  2
                    })
                })
            }));

            this.startEndPointSource.addFeature(this.startPointFeature);

        }

        const geom: Geometry = new Point(fromLonLat([latLon.lon, latLon.lat]));

        this.startPointFeature.setGeometry(geom);

    }

    /**
     * Function to remove start point feature
     */
    public removeInterPoint(): void {

        this.startEndPointLayer.getSource().removeFeature(this.interPointFeature);
        this.interPointFeature = undefined;
        // Planner.setEndPoint(null);
        // Elements.planBttnStatus();

    }

    /**
     * Function to add inter point to map and to planner class
     *
     * @param results geocoding result
     * @param status geocoding status
     */
    public addIntermediatePoint(latlon: LatLonI): void {

        if (!this.interPointFeature) {

            this.interPointFeature = new Feature();
            this.interPointFeature.setStyle(new Style({
                image:  new Circle({
                    radius:  8,
                    fill:  new Fill({
                        color:  '#4271f4'
                    }),
                    stroke:  new Stroke({
                        color:  '#fff',
                        width:  2
                    })
                })
            }));

            this.startEndPointSource.addFeature(this.interPointFeature);

        }

        this.interPointFeature.setGeometry(

            new Point(fromLonLat([latlon.lon, latlon.lat]))

        );

    }

    /**
     * Function to remove start point feature
     */
    public removeStartPoint(): void {

        this.startEndPointLayer.getSource().removeFeature(this.startPointFeature);
        this.startPointFeature = undefined;
        // Planner.setEndPoint(null);
        // Elements.planBttnStatus();

    }

    /**
     * Function to add destination point to map and planner class
     *
     * @param results geocoding result
     * @param status geocoding status
     */
    public addEndPoint(latlon: LatLonI): void {

        if (!this.endPointFeature) {

            this.endPointFeature = new Feature();
            this.endPointFeature.setStyle(new Style({
                image:  new Circle({
                    radius:  8,
                    fill:  new Fill({
                        color:  '#990404'
                    }),
                    stroke:  new Stroke({
                        color:  '#fff',
                        width:  2
                    })
                })
            }));

            this.startEndPointSource.addFeature(this.endPointFeature);

        }

        this.endPointFeature.setGeometry(

            new Point(fromLonLat([latlon.lon, latlon.lat]))

        );


    }

    /**
     * Function to remove end point feature
     */
    public removeEndPoint(): void {

        this.startEndPointLayer.getSource().removeFeature(this.endPointFeature);
        this.endPointFeature = undefined;

    }

    /**
     * Function to create start endpoint layer for map
     */
    private initStartEndPointLayer(): void {

        if (!this.startEndPointLayer) {

            this.startEndPointLayer = new VectorLayer();
            this.map.addLayer(this.startEndPointLayer);

        }

        if (!this.startEndPointSource) {

            this.startEndPointSource = new VectorSource();
            this.startEndPointLayer.setSource(this.startEndPointSource);

        }

    }

    private addProjarea(data: any) {

        const geojson = new GeoJSON().readFeatures(data, {
            featureProjection: 'EPSG:3857',
            dataProjection: 'EPSG:4326'
        });

        const source = new VectorSource({
            features: geojson
        });

        source.getFeatures().forEach(feature => {

            if (feature.get('style') === 0) {

                this.projarea = feature;
                this.plannerService.setProjectArea(this.projarea);

            }

        });

        const layer = new VectorLayer({
            source: source,
            style: this.projStyleFunction
        });

        this.map.addLayer(layer);

    }

    private projStyleFunction(feature) {

        let opacity = 0.5;

        if (feature.get('style') === 0) {
            opacity = 0;
        }

        const style = new Style({
            stroke: new Stroke({
              color: 'blue',
              width: 3
            }),
            fill: new Fill({
              color: `rgba(255, 255, 255, ${opacity})`
            })
          });

          return style;

    }

}
