import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  private progBarStatus: boolean;
  public progBarStausEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  public showProgressBar(status: boolean) {

    this.progBarStatus = status;
    this.progBarStausEmitter.emit(this.progBarStatus);

  }

  public getProgressBarStatus(): boolean {

    return this.progBarStatus;

  }

}
