import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { MainMenubarComponent } from './main-menubar/main-menubar.component';
import { OlMapComponent } from './ol-map/ol-map.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MainContentComponent } from './main-content/main-content.component';
import { PlacesInputComponent } from './places-input/places-input.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { DatePipe } from '@angular/common';
import { SidePlanComponent } from './side-plan/side-plan.component';
import { SideResultComponent } from './side-result/side-result.component';
import { SideEcofootprintComponent } from './side-ecofootprint/side-ecofootprint.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RouteLayerComponent } from './route-layer/route-layer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { EcoModalComponent } from './eco-modal/eco-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    MainMenubarComponent,
    OlMapComponent,
    SidebarComponent,
    MainContentComponent,
    PlacesInputComponent,
    MultiSelectComponent,
    SidePlanComponent,
    SideResultComponent,
    SideEcofootprintComponent,
    RouteLayerComponent,
    LanguageSelectorComponent,
    EcoModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    NgxMaterialTimepickerModule.forRoot(),
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
  entryComponents: [
    EcoModalComponent
  ]
})
export class AppModule { }
