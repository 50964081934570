import { Component, OnInit } from '@angular/core';
import { ConfigServiceService } from './config-service.service';
import { ConfigI } from '../interfaces/configI';
import { LanguageServiceService } from './language-service.service';
import { LanguageI } from '../interfaces/languageI';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  public configData: ConfigI;
  public languageData: LanguageI = <LanguageI>{};
  private title = 'SPMap';
  private isMobileB: boolean;
  private languageType: string;
  public showMapValue = true;

  constructor(
    private configService: ConfigServiceService,
    private languageService: LanguageServiceService
  ) {

    this.isMobileB = this.isMobile();

  }

  ngOnInit(): void {
    this.getConfigData();
    this.getLanguageData();
  }

  private getConfigData(): void {
    this.configService.getConfig().subscribe(configData => this.configData = configData);
  }

  private getLanguageData() {
    this.languageService.getLanguageData().subscribe(languageData => this.languageData = languageData);
    this.languageService.languageEmitter.subscribe(data => this.languageData = data);
  }

  /**
   * Function to detect device is mobile or not
   */
  private isMobile(): boolean {

      let isMobile: boolean;

      if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {

          isMobile = true;

      } else {

          isMobile = false;

      }

      return isMobile;

  }

  public showMap() {

    this.showMapValue = true;

  }

  public hideMap() {

    this.showMapValue = false;

  }

}
