import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material';
import { LanguageServiceService } from '../language-service.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css']
})
export class LanguageSelectorComponent implements OnInit {

  protected selected: string;

  constructor(private languageService: LanguageServiceService) { }

  ngOnInit() {

    this.selected = this.languageService.getLanguage();

  }

  protected languageChaneges(event: MatSelectChange) {

    this.languageService.setLanguage(event.value);

  }

}
